import ActiveEditorContext from "../../../../providers/ActiveEditorContext";
import StoreDataContext from "../../../../providers/StoreDataContext";
import TreeContext from "../../../../providers/TreeContext";
import TaskEditor from "../TaskEditor";
import Add from "./Buttons/Add";
import MandatoryField from "./Fields/Mandatory";
import Dependencies from "./Fields/Dependencies";
import NameField from "./Fields/Name";
import TypeField from "./Fields/Type";
import getTaskType from "./Functions/getTaskType";
import messageError from "./Functions/messageError";
import TemplateLibInterview from "@common/libs/interview-template-lib";
import StripeLib from "@common/libs/stripe-lib";
import TemplateLib from "@common/libs/template-lib";
import { Grid, Divider } from "@material-ui/core";
import { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import config from "~/config";

export default function FormTask() {
  const { activeEditor, updateActiveEditor } = useContext(ActiveEditorContext);
  const { tree, updateTree } = useContext(TreeContext);
  const [_loading, set_loading] = useState(false);
  const { storeData, updateStoreData } = useContext(StoreDataContext);
  const [_formData, set_formData] = useState({
    name: "",
    details: "/",
    description: "/",
    category_id: activeEditor.root[1].id,
    mandatory: false,
    actions: [
      {
        type: "text",
        interview_id: undefined,
        interview_response_id: undefined,
        data: {
          text: "",
        },
      },
    ],
  });

  const [_paymentForm, set_paymentForm] = useState({
    products: [{ amount: 0, name: "" }],
    tax_rate: 20,
  });

  const getPrice = (arr) => {
    var total = 0;
    for (var i in arr) {
      total += +arr[i].amount;
    }
    return total;
  };

  useEffect(() => {
    if (
      _formData.actions[0]?.data?.document_templates &&
      (_formData.actions[0].type === "electronic" ||
        _formData.actions[0].type === "courrier")
    ) {
      let result = { ..._formData };
      result.actions[0].data.document_templates = [];
      set_formData(result);
    }
  }, [_formData.actions[0].type]);

  const addTask = () => {
    set_loading(true);
    let tasksOrder = { ...tree };
    let formattedData = { ..._formData };
    const projectIndex = tasksOrder.projects.findIndex(
      (el) => el.id === activeEditor.root[0].id
    );
    let sectionIndex = 0;

    if (activeEditor.root_key.length === 3) {
      if (
        activeEditor.root[1].tasks !== undefined &&
        activeEditor.root[1].tasks !== "loading" &&
        activeEditor.root[1].tasks.length > 0
      ) {
        tasksOrder = activeEditor.root[1].tasks.at(-1).order + 1;
      } else {
        tasksOrder = 0;
      }
      formattedData.order = tasksOrder;
    } else {
      sectionIndex = tasksOrder.projects[projectIndex].sections.findIndex(
        (el) => el.id === activeEditor.root[1].id
      );
      tasksOrder =
        tasksOrder.projects[projectIndex].sections[sectionIndex].tasks;
      formattedData.order = tasksOrder.length
        ? tasksOrder[tasksOrder.length - 1].order + 1
        : 0;
    }
    if (formattedData.name !== "") {
      if (formattedData.actions[0].type === "payment") {
        formattedData.actions[0].data.products = _paymentForm.products;

        if (_paymentForm.tax_rate === 0 || _paymentForm.tax_rate === "0") {
          formattedData.actions[0].data.tax_rate = null;
        } else if (
          storeData.taxes.findIndex(
            (el) => el.percentage === +_paymentForm.tax_rate
          ) > -1
        ) {
          formattedData.actions[0].data.tax_rate = storeData.taxes.find(
            (el) => el.percentage === +_paymentForm.tax_rate
          );

          StripeLib.addProduct({
            name: _paymentForm.products.map((el) => el.name).join(" + "),
          })
            .then((res) => {
              StripeLib.addPrice({
                name: _paymentForm.products.map((el) => el.name).join(" + "),
                amount: getPrice(_paymentForm.products),
                product: res.uuid,
                amountType: "one_time",
              })
                .then((resolve) => {
                  formattedData.actions[0].data.price_uuid = resolve.uuid;
                  formattedData.actions[0].data.product_uuid = res.uuid;
                  formattedData.actions[0].data = JSON.stringify(
                    formattedData.actions[0].data
                  );
                  addValidTask(formattedData, _formData.actions[0].data);
                })
                .catch((err) => {
                  set_loading(false);
                  messageError(
                    err,
                    "durant l'ajout du prix dans la base de données"
                  );
                });
            })
            .catch((err) => {
              set_loading(false);
              messageError(
                err,
                "durant l'ajout du produit dans la base de données"
              );
            });
        } else {
          StripeLib.createTaxe({
            name: "" + _paymentForm.tax_rate,
            percentage: +_paymentForm.tax_rate,
          }).then((ress) => {
            formattedData.actions[0].data.tax_rate = ress;

            StripeLib.addProduct({
              name: _paymentForm.products.map((el) => el.name).join(" + "),
            })
              .then((res) => {
                StripeLib.addPrice({
                  name: _paymentForm.products.map((el) => el.name).join(" + "),
                  amount: getPrice(_paymentForm.products),
                  product: res.uuid,
                  amountType: "one_time",
                })
                  .then((resolve) => {
                    formattedData.actions[0].data.price_uuid = resolve.uuid;
                    formattedData.actions[0].data.product_uuid = res.uuid;
                    formattedData.actions[0].data = JSON.stringify(
                      formattedData.actions[0].data
                    );
                    addValidTask(formattedData, _formData.actions[0].data);
                  })
                  .catch((err) => {
                    set_loading(false);
                    messageError(
                      err,
                      "durant l'ajout du prix dans la base de données"
                    );
                  });
              })
              .catch((err) => {
                set_loading(false);
                messageError(
                  err,
                  "durant l'ajout du produit dans la base de données"
                );
              });
          });
        }
      } else if (formattedData.actions[0].type === "form") {
        /////// BUG #### 3
        formattedData.actions[0].data.schema =
          formattedData.actions[0].data.schema.map((elem) => {
            if (elem.type === "radio") {
              let result = { ...elem };
              result.radio = result.radio.map((el, key) => ({
                value: key,
                label: el,
              }));
              return result;
            } else if (elem.type === "select") {
              let result = { ...elem };
              result.list = result.list.map((el, key) => ({
                value: key,
                label: el,
              }));
              return result;
            } else return elem;
          });
        formattedData.actions[0].data = JSON.stringify(
          formattedData.actions[0].data
        );
        addValidTask(formattedData, _formData.actions[0].data);
      } else {
        formattedData.actions[0].data = JSON.stringify(
          formattedData.actions[0].data
        );
        addValidTask(formattedData, _formData.actions[0].data);
      }
    } else {
      toast.error(<div>Un nom est requis pour la tâche</div>);
      set_loading(false);
    }
  };

  const addValidTask = (formattedData, actionsData) => {
    TemplateLib.newTask(formattedData)
      .then((res) => {
        const interviews = JSON.parse(
          formattedData.actions[0].data
        )?.interview_id;
        if (
          formattedData.actions[0].type === "interview" &&
          interviews?.length
        ) {
          Promise.all(
            interviews.map((el) => TemplateLibInterview.getDocument(el))
          ).then((docs) => {
            Promise.all(
              docs.map((el) =>
                TemplateLibInterview.updateDocument(el.id, {
                  ...el,
                  task: res.id,
                })
              )
            ).then(() => {
              TemplateLib.setActionsTask(res.id, formattedData.actions).then(
                (response) => {
                  let result = { ...tree };

                  const projectIndex = result.projects.findIndex(
                    (el) => el.id === activeEditor.root[0].id
                  );

                  const sectionIndex = result.projects[
                    projectIndex
                  ].sections.findIndex(
                    (el) => el.id === activeEditor.root[1].id
                  );
                  let realResult = { ...res };
                  realResult.actions[0] = { ...response[0], data: actionsData };

                  TemplateLib.getListTaskOfCategories(activeEditor.root[1].id)
                    .then((res) => {
                      let sorted = res;
                      sorted.sort(function (a, b) {
                        return a.order - b.order;
                      });
                      let result = { ...tree };
                      if (activeEditor.root_key.length === 2) {
                        result.projects[activeEditor.root_key[0]].sections[
                          activeEditor.root_key[1]
                        ].tasks = sorted;
                      }
                      if (activeEditor.root_key.length === 3) {
                        result.projects[activeEditor.root_key[0]].sections[
                          activeEditor.root_key[1]
                        ].sections[activeEditor.root_key[2]].tasks = sorted;
                      }
                      updateTree(result);
                    })
                    .catch((e) => {
                      console.error("error get tasks of categorie", e);
                    });
                  updateActiveEditor({
                    type: "task",
                    data: realResult,
                    root: [...activeEditor.root, realResult],
                    root_key: [
                      ...activeEditor.root_key,
                      result.projects[projectIndex].sections[sectionIndex].tasks
                        .length,
                    ],
                  });
                  toast.success("Nouvelle tâche enregistrée.");
                  set_loading(false);
                }
              );
            });
          });
        } else {
          TemplateLib.setActionsTask(res.id, formattedData.actions).then(
            (response) => {
              let result = { ...tree };
              TemplateLib.getListTaskOfCategories(activeEditor.root[1].id)
                .then((res) => {
                  let sorted = res;
                  sorted.sort(function (a, b) {
                    return a.order - b.order;
                  });
                  let result = { ...tree };
                  if (activeEditor.root_key.length === 2) {
                    result.projects[activeEditor.root_key[0]].sections[
                      activeEditor.root_key[1]
                    ].tasks = sorted;
                  }
                  if (activeEditor.root_key.length === 3) {
                    result.projects[activeEditor.root_key[0]].sections[
                      activeEditor.root_key[1]
                    ].sections[activeEditor.root_key[2]].tasks = sorted;
                  }
                  updateTree(result);
                })
                .catch((e) => {
                  console.error("error get tasks of categorie", e);
                });
              toast.success("Nouvelle tâche enregistrée.");
              set_loading(false);
            }
          );
        }
      })
      .catch((err) => {
        set_loading(false);
        messageError(err, "durant l'ajout de la tâche");
      });
  };

  return (
    activeEditor.type === "add_task" && (
      <Grid container spacing={2}>
        <NameField
          _formData={_formData}
          set_formData={set_formData}
          label="Nom de la tâche"
        />
        <TypeField
          _formData={_formData}
          set_formData={set_formData}
          _paymentForm={_paymentForm}
          set_paymentForm={set_paymentForm}
        />
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TaskEditor _formData={_formData} set_formData={set_formData} />
        </Grid>
        <MandatoryField
          _formData={_formData}
          set_formData={set_formData}
          label={"Tâche facultative "}
        />
        {_formData.mandatory && (
          <Dependencies
            _formData={_formData}
            set_formData={set_formData}
            activeEditor={activeEditor}
          ></Dependencies>
        )}
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Divider
            style={{
              margin: "10px 10% 20px 10%",
              width: "80%",
              height: "1px",
              borderRadius: "5px",
              backgroundColor: `rgba(${
                localStorage.getItem("color-task")
                  ? localStorage.getItem("color-task")
                  : config.defaultColors.task
              },1)`,
            }}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {getTaskType(
            _formData.actions[0].type,
            _formData,
            set_formData,
            _paymentForm,
            set_paymentForm,
            addTask
          )}
        </Grid>
        <Add _function={addTask} loading={_loading} />
      </Grid>
    )
  );
}
