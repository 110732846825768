import TooltipHelp from "../components/template-manager/TooltipHelp";
import CategoriesFilter from "../components/template-manager/filters/Categories";
import ProjectNamesFilter from "../components/template-manager/filters/Projects";
import SpecialitiesFilter from "../components/template-manager/filters/Specialities";
import ListSections from "../components/template-manager/left/ListSections";
import Editor from "../components/template-manager/right/EditorContainer";
import ActiveEditorContext from "../providers/ActiveEditorContext";
import StoreDataContext from "../providers/StoreDataContext";
import TreeContext from "../providers/TreeContext";
import SpecialtiesLib from "@common/libs/specialties-lib";
import TemplateLib from "@common/libs/template-lib";
import {
  Grid,
  Paper,
  Divider,
  Button,
  Fade,
  Slide,
  CircularProgress,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TreeView from "@material-ui/lab/TreeView";
import React, { useState, useContext, useEffect } from "react";
import FiltersContext from "~/providers/FiltersContext";
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    minHeight: "200px",
    "& h3": {
      marginLeft: "30px",
    },
    "& b": {
      fontSize: "18px",
      marginRight: "5px",
    },
  },
});

const TemplateManager = () => {
  const { tree, updateTree } = useContext(TreeContext);
  const { activeEditor, updateActiveEditor } = useContext(ActiveEditorContext);
  const { storeData, updateStoreData } = useContext(StoreDataContext);
  const { filtersData } = useContext(FiltersContext);

  const classes = useStyles();

  const [initSite, setInitSite] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const list_entities = []
    .concat(storeData.groups)
    .concat(storeData.enterprises)
    .concat(storeData.establishments);

  useEffect(() => {
    if (filtersData.site !== null) {
      sessionStorage.setItem("filters", JSON.stringify(filtersData));
      updateTree({ projects: "loading", selected: "", expanded: [] });
      updateActiveEditor({ type: null, data: null });

      TemplateLib.getListSubdomains(filtersData.site.domain).then(
        (response) => {
          SpecialtiesLib.getSpecialties().then((res) => {
            updateStoreData((storeData) => ({
              ...storeData,
              subdomains: response,
              specialities: res.map((elem) => {
                let result = { ...elem };
                result.subdomains = response.filter(
                  (el) => el.specialty_id === elem.id
                );
                return result;
              }),
            }));

            TemplateLib.getListTemplateProjects(filtersData.site.domain)
              .then((res) => {
                let result = [...res];
                result = result.map((el) => ({
                  ...el,
                  entities: el.entities.map((entity) => {
                    return list_entities.find((elem) => elem.id === entity.id);
                  }),
                }));
                result.sort(function (a, b) {
                  if (a.name < b.name) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }
                  return 0;
                });
                result = result
                  .filter((el) => el.entities?.length !== 0)
                  .concat(result.filter((el) => el.entities?.length === 0));

                if (filtersData.entities?.length !== 0) {
                  result = result.filter((elem) =>
                    elem.entities?.length === 0
                      ? elem
                      : elem.entities.findIndex(
                          (el) => el.id === filtersData.entities[0].id
                        ) > -1
                  );
                }

                if (
                  filtersData.project !== null &&
                  filtersData.project !== ""
                ) {
                  result = result.filter(
                    (elem) =>
                      elem.name
                        .toLowerCase()
                        .includes(filtersData.project.toLowerCase()) ||
                      elem.id
                        .toString()
                        .includes(filtersData.project.toLowerCase())
                  );
                }

                if (
                  filtersData.speciality !== null &&
                  filtersData.speciality !== "" &&
                  filtersData.speciality !== 1
                ) {
                  result = result.filter(
                    (elem) =>
                      elem.projectspecialties.findIndex(
                        (el) => el === filtersData.speciality
                      ) > -1
                  );
                }
                if (
                  filtersData.categories !== null &&
                  filtersData.categories !== ""
                ) {
                  result = result.filter(
                    (elem) =>
                      elem.subdomains.findIndex(
                        (el) => el.id === filtersData.categories.id
                      ) > -1
                  );
                }

                if (filtersData.site !== initSite) {
                  updateTree({
                    ...tree,
                    selected: "",
                    expanded: [],
                    projects: result.map((elem) => ({
                      ...elem,
                      sections: "loading",
                    })),
                  });

                  setInitSite(filtersData.site);
                } else {
                  updateTree({
                    ...tree,
                    projects: result.map((elem) => ({
                      ...elem,
                      sections: "loading",
                    })),
                  });
                }

                if (tree.expanded.length > 0) {
                  tree.expanded.forEach((el) => {
                    const splited = el.split("-");
                    if (splited.length === 1) {
                      tree.projects[el].sections = [];
                      getCategorie(tree.projects[el].id, el).then((res) => {
                        const oldActiveEditor = activeEditor;
                        updateTree({ ...res, expanded: tree.expanded });
                        updateActiveEditor({ ...oldActiveEditor });
                      });
                    }
                  });
                }
              })
              .catch(() => {
                updateTree({ selected: "", expanded: [], projects: [] });
              });
          });
        }
      );
    }
  }, [filtersData]);

  const addProject = () => {
    updateActiveEditor({ type: "add_project", data: null });
    updateTree({ ...tree, selected: "" });
  };

  const handleToggle = (event, nodeIds) => {
    const classString = event.target.getAttribute("class");
    const dParam =
      event.target.getAttribute("d")?.indexOf("M10 6L8.59") > -1 ||
      event.target.getAttribute("d")?.indexOf("M16.59") > -1;
    if (
      classString?.indexOf("icon_chevron") > -1 ||
      classString?.indexOf("iconContainer") > -1 ||
      dParam
    ) {
      if (
        nodeIds.length > 0 &&
        tree.projects[nodeIds[0]]?.sections === "loading"
      ) {
        getCategorie(tree.projects[nodeIds[0]].id, nodeIds[0]).then((res) => {
          updateTree({ ...res, expanded: nodeIds });
        });
      } else {
        updateTree({ ...tree, expanded: nodeIds });
      }
    }
  };

  const handleSelect = (event, nodeIds) => {
    const classString = event.target.getAttribute("class");
    const dParam =
      event.target.getAttribute("d")?.indexOf("M10 6L8.59") === -1 &&
      event.target.getAttribute("d")?.indexOf("M16.59") === -1;
    if (classString?.indexOf("clickable_line") > -1 || dParam) {
      updateTree({ ...tree, selected: nodeIds });
    }
  };

  async function getCategorie(id, index) {
    return TemplateLib.getListCategoriesOfProject(id).then((res) => {
      let sorted = res;
      sorted.sort(function (a, b) {
        return a.order - b.order;
      });

      let result = { ...tree };
      if (sorted.length > 0) {
        let sortedIndex = 0;
        sorted.forEach((element) => {
          if (element.category_id === null) {
            if (result.projects[index].sections === "loading") {
              result.projects[index].sections = [];
            }
            result.projects[index].sections.push({
              ...element,
              order: sortedIndex,
              tasks: "loading",
            });
            sortedIndex++;
          }
        });

        let sortedChildrenIndex = 0;
        sorted.forEach((element) => {
          if (element.category_id) {
            const sectionIndex = result.projects[index].sections.findIndex(
              (el) => {
                return el.id === element.category_id;
              }
            );
            if (result.projects[index].sections[sectionIndex]) {
              if (
                result.projects[index].sections[sectionIndex]?.sections ===
                undefined
              ) {
                result.projects[index].sections[sectionIndex]["sections"] = [];
              }
              result.projects[index].sections[sectionIndex].sections.push({
                ...element,
                tasks: "loading",
                order:
                  result.projects[index].sections[sectionIndex].sections
                    .length + 1,
              });
              sortedChildrenIndex++;
            }
          }
        });
      } else {
        result.projects[index].sections = sorted.map((index, elem) => ({
          ...elem,
          tasks: "loading",
        }));
      }
      return result;
    });
  }

  return (
    <div style={{ overflow: "hidden" }}>
      <Slide
        direction="left"
        in={true}
        mountOnEnter
        unmountOnExit
        {...{ timeout: 1000 }}
      >
        <div>
          <Fade in={true} {...{ timeout: 1000 + 1000 }}>
            <div>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <h1 style={{ margin: "20px 0 20px 0" }}>
                    Modèles de projets
                  </h1>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <ProjectNamesFilter />
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <SpecialitiesFilter />
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <CategoriesFilter />
                </Grid>
                {/* <Grid item lg={4} md={4} sm={12} xs={12}>
                                    <EntitiesFilter />
                                </Grid> */}
                <Grid item lg={7} md={7} sm={7} xs={7}></Grid>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  <Paper style={{ padding: "10px" }} elevation={6}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <TooltipHelp />
                      <Button
                        disabled={tree.projects === "loading"}
                        variant="contained"
                        color="primary"
                        onClick={addProject}
                      >
                        + Ajouter un modèle de projet
                      </Button>
                    </div>
                    <Divider
                      style={{ margin: "10px 10% 15px 10%", width: "80%" }}
                    />
                    {filtersData.site === null ? (
                      <div
                        style={{
                          minHeight: "250px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h4>Veuillez sélectionner un Périmètre</h4>
                      </div>
                    ) : tree.projects === "loading" ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          minHeight: "250px",
                        }}
                      >
                        <CircularProgress size={20} />
                      </div>
                    ) : (
                      <TreeView
                        expanded={tree.expanded}
                        selected={tree.selected}
                        onNodeToggle={handleToggle}
                        onNodeSelect={handleSelect}
                        className={classes.root}
                        defaultCollapseIcon={
                          <ExpandMoreIcon
                            style={{ fontSize: 26 }}
                            className="icon_chevron"
                          />
                        }
                        defaultExpandIcon={
                          <ChevronRightIcon
                            style={{ fontSize: 26 }}
                            className="icon_chevron"
                          />
                        }
                      >
                        {tree.projects?.length > 0 ? (
                          tree.projects.map((elem, key) => {
                            return (
                              <ListSections data={elem} key={key} index={key} />
                            );
                          })
                        ) : (
                          <div>
                            <h3>Aucun template trouvé pour la recherche :</h3>
                            <ul>
                              {filtersData.site !== null && (
                                <li>
                                  <b>Périmètre : </b>
                                  {filtersData.site.name} -{" "}
                                  {filtersData.site.domain}
                                </li>
                              )}
                              {filtersData.project !== "" &&
                                filtersData.project !== "" && (
                                  <li>
                                    <b>Projet : </b>
                                    {filtersData.project}
                                  </li>
                                )}
                              {filtersData.speciality !== null &&
                                filtersData.speciality !== 1 && (
                                  <li>
                                    <b>Spécialité :</b>{" "}
                                    {
                                      storeData.specialities.find(
                                        (el) => el.id === filtersData.speciality
                                      ).name
                                    }
                                  </li>
                                )}
                              {filtersData.categories !== null && (
                                <li>
                                  <b>Sous-spécialité :</b>{" "}
                                  {
                                    storeData.subdomains.find(
                                      (el) =>
                                        el.id === filtersData.categories.id
                                    ).name
                                  }
                                </li>
                              )}
                              {filtersData.entities?.length !== 0 && (
                                <li>
                                  <b>Entité : </b>
                                  {filtersData.entities[0].render_type.slice(
                                    0,
                                    -1
                                  )}{" "}
                                  - {filtersData.entities[0].name}
                                </li>
                              )}
                            </ul>
                          </div>
                        )}
                      </TreeView>
                    )}
                  </Paper>
                </Grid>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  <Editor />
                </Grid>
              </Grid>
            </div>
          </Fade>
        </div>
      </Slide>
    </div>
  );
};
export default TemplateManager;
