import ActiveEditorContext from "../../../../providers/ActiveEditorContext";
import TreeContext from "../../../../providers/TreeContext";
import Delete from "./Buttons/Delete";
import Modify from "./Buttons/Modify";
import DescriptionField from "./Fields/Description";
import MandatoryField from "./Fields/Mandatory";
import Dependencies from "./Fields/Dependencies";
import NameField from "./Fields/Name";
import TemplateLib from "@common/libs/template-lib";
import {
  Grid,
  CircularProgress,
  Button,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import config from "~/config";
import SectionEditor from "../SectionEditor";
import FiltersContext from "~/providers/FiltersContext";

export default function FormSection() {
  const { activeEditor, updateActiveEditor } = useContext(ActiveEditorContext);
  const { tree, updateTree } = useContext(TreeContext);
  let realData = { ...activeEditor.data };
  delete realData.risk;
  const [_formData, set_formData] = useState(realData);
  const [loading, setloading] = useState(false);
  const [_loading, set_loading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const { filtersData, updateFiltersData } = useContext(FiltersContext);

  const result = { ...tree };
  const projectIndex = result.projects.findIndex(
    (el) => el.id === activeEditor.root[0].id
  );

  let sectionIndex = result.projects[projectIndex].sections?.findIndex(
    (el) => el.id === activeEditor.root[1].id
  );

  if (realData.category_id) {
    sectionIndex = activeEditor.root_key[2];
  }

  useEffect(() => {
    TemplateLib.getListCategoriesOfProject(activeEditor.data.project_id).then(
      (categories) => {
        setAllCategories(categories);
        let sortedParentsCategories = [];
        sortedParentsCategories = categories
          .filter((categorie) => categorie.category_id === null)
          .sort((a, b) => a.order - b.order);

        setCategories(sortedParentsCategories);
      }
    );
  }, []);

  const editSection = () => {
    set_loading(true);
    TemplateLib.editCategorie(activeEditor.data.id, _formData)
      .then((res) => {
        updateActiveEditor({ ...activeEditor, data: _formData });
        updateFiltersData(() => ({
          ...filtersData,
        }));

        toast.success(
          'Modification(s) de la section : "' + res.name + '" enregistrée(s).'
        );
        set_loading(false);
      })
      .catch((response) => {
        set_loading(false);
        config.handleErrorBackend({ response, message: "" });
      });
  };

  const deleteSection = () => {
    if (
      window.confirm("Supprimer la section : " + activeEditor.data.name + " ?")
    ) {
      set_loading(true);

      TemplateLib.deleteCategorie(activeEditor.data.id)
        .then((res) => {
          let sectionsOrder = tree.projects[
            activeEditor.root_key[0]
          ].sections.filter(
            (el) =>
              el.id !== activeEditor.data.id &&
              el.order > activeEditor.data.order
          );
          Promise.all(
            sectionsOrder.map((elem) =>
              TemplateLib.editCategorie(elem.id, {
                ...elem,
                risk: "/",
                order: (elem.order -= 1),
              })
            )
          ).then((res) => {
            updateActiveEditor({ type: null, data: null });
            updateFiltersData(() => ({
              ...filtersData,
            }));
            toast.success(
              'Suppression de la section : "' +
                activeEditor.data.name +
                '" enregistrée.'
            );
            set_loading(false);
          });
        })
        .catch((response) => {
          set_loading(false);
          config.handleErrorBackend({ response, message: "" });
        });
    }
  };

  const OrderUp = () => {
    setloading(true);
    if (_formData.category_id === null) {
      if (sectionIndex - 1 !== -1) {
        const prev = result.projects[projectIndex].sections[sectionIndex - 1];
        result.projects[projectIndex].sections[sectionIndex - 1] = {
          ...activeEditor.data,
          order: prev.order,
        };
        result.projects[projectIndex].sections[sectionIndex] = {
          ...prev,
          order: activeEditor.data.order,
        };
        let newExpand = result.expanded;
        const oldValue = activeEditor.root_key.join("-");
        const newValue =
          activeEditor.root_key[0] + "-" + (activeEditor.root_key[1] - 1);

        if (newExpand.indexOf(oldValue) > -1) {
          // L'origin est ouvert
          if (newExpand.indexOf(newValue) === -1) {
            // L'origin est ouvert & la target est fermée
            newExpand.push(newValue);
            newExpand = newExpand.filter((el) => el !== oldValue);
          }
        } else {
          // L'origin est fermée
          if (newExpand.indexOf(newValue) > -1) {
            // L'origin est fermée & la target est ouverte
            newExpand.push(oldValue);
            newExpand = newExpand.filter((el) => el !== newValue);
          }
        }
        delete activeEditor.data.risk;
        delete prev.risk;
        TemplateLib.editCategorie(activeEditor.data.id, {
          ...activeEditor.data,
          order: prev.order,
        }).then(() => {
          TemplateLib.editCategorie(prev.id, {
            ...prev,
            order: activeEditor.data.order,
          }).then(() => {
            updateTree({
              ...result,
              selected: [projectIndex, sectionIndex - 1].join("-"),
              expanded: newExpand,
            });
            updateActiveEditor({
              ...activeEditor,
              root_key: [projectIndex, sectionIndex - 1],
              data: { ...activeEditor.data, order: prev.order },
            });
            setloading(false);
          });
        });
      }
    } else {
      if (activeEditor.root_key[2] - 1 !== -1) {
        const prev =
          result.projects[projectIndex].sections[activeEditor.root_key[1]]
            .sections[activeEditor.root_key[2] - 1];

        result.projects[projectIndex].sections[
          activeEditor.root_key[1]
        ].sections[activeEditor.root_key[2] - 1] = {
          ...activeEditor.data,
          order: prev.order,
        };
        result.projects[projectIndex].sections[
          activeEditor.root_key[1]
        ].sections[activeEditor.root_key[2]] = {
          ...prev,
          order: activeEditor.data.order,
        };
        let newExpand = result.expanded;
        const oldValue = activeEditor.root_key.join("-");
        const newValue =
          activeEditor.root_key[0] +
          "-" +
          activeEditor.root_key[1] +
          "-" +
          (activeEditor.root_key[2] - 1);

        if (newExpand.indexOf(oldValue) > -1) {
          // L'origin est ouvert
          if (newExpand.indexOf(newValue) === -1) {
            // L'origin est ouvert & la target est fermée
            newExpand.push(newValue);
            newExpand = newExpand.filter((el) => el !== oldValue);
          }
        } else {
          // L'origin est fermée
          if (newExpand.indexOf(newValue) > -1) {
            // L'origin est fermée & la target est ouverte
            newExpand.push(oldValue);
            newExpand = newExpand.filter((el) => el !== newValue);
          }
        }
        delete activeEditor.data.risk;
        delete prev.risk;

        TemplateLib.editCategorie(activeEditor.data.id, {
          ...activeEditor.data,
          order: prev.order,
        }).then(() => {
          TemplateLib.editCategorie(prev.id, {
            ...prev,
            order: activeEditor.data.order,
          }).then(() => {
            updateTree({
              ...result,
              selected: [
                projectIndex,
                activeEditor.root_key[1],
                activeEditor.root_key[2] - 1,
              ].join("-"),
              expanded: newExpand,
            });
            updateActiveEditor({
              ...activeEditor,
              root_key: [
                projectIndex,
                activeEditor.root_key[1],
                activeEditor.root_key[2] - 1,
              ],
              data: { ...activeEditor.data, order: prev.order },
            });
            setloading(false);
          });
        });
      }
    }
  };

  const OrderDown = () => {
    setloading(true);
    if (_formData.category_id === null) {
      if (sectionIndex + 1 !== result.projects[projectIndex].sections.length) {
        const next = result.projects[projectIndex].sections[sectionIndex + 1];
        result.projects[projectIndex].sections[sectionIndex + 1] = {
          ...activeEditor.data,
          order: next.order,
        };
        result.projects[projectIndex].sections[sectionIndex] = {
          ...next,
          order: activeEditor.data.order,
        };
        let newExpand = result.expanded;
        const oldValue = activeEditor.root_key.join("-");
        const newValue =
          activeEditor.root_key[0] + "-" + (activeEditor.root_key[1] + 1);
        if (newExpand.indexOf(oldValue) > -1) {
          // L'origin est ouvert
          if (newExpand.indexOf(newValue) === -1) {
            // L'origin est ouvert & la target est fermée
            newExpand.push(newValue);
            newExpand = newExpand.filter((el) => el !== oldValue);
          }
        } else {
          // L'origin est fermée
          if (newExpand.indexOf(newValue) > -1) {
            // L'origin est fermée & la target est ouverte
            newExpand.push(oldValue);
            newExpand = newExpand.filter((el) => el !== newValue);
          }
        }
        delete activeEditor.data.risk;
        delete next.risk;
        TemplateLib.editCategorie(activeEditor.data.id, {
          ...activeEditor.data,
          order: next.order,
        }).then(() => {
          TemplateLib.editCategorie(next.id, {
            ...next,
            order: activeEditor.data.order,
          }).then(() => {
            updateTree({
              ...result,
              selected: [projectIndex, sectionIndex + 1].join("-"),
              expanded: newExpand,
            });
            updateActiveEditor({
              ...activeEditor,
              root_key: [projectIndex, sectionIndex + 1],
              data: { ..._formData, order: next.order },
            });
            setloading(false);
          });
        });
      }
    } else {
      if (
        activeEditor.root_key[2] + 1 !==
        result.projects[projectIndex].sections[activeEditor.root_key[1]]
          .sections.length
      ) {
        const next =
          result.projects[projectIndex].sections[activeEditor.root_key[1]]
            .sections[activeEditor.root_key[2] + 1];
        result.projects[projectIndex].sections[
          activeEditor.root_key[1]
        ].sections[activeEditor.root_key[2] + 1] = {
          ...activeEditor.data,
          order: next.order,
        };
        result.projects[projectIndex].sections[
          activeEditor.root_key[1]
        ].sections[activeEditor.root_key[2]] = {
          ...next,
          order: activeEditor.data.order,
        };
        let newExpand = result.expanded;
        const oldValue = activeEditor.root_key.join("-");
        const newValue =
          activeEditor.root_key[0] +
          "-" +
          activeEditor.root_key[1] +
          "-" +
          (activeEditor.root_key[2] + 1);
        if (newExpand.indexOf(oldValue) > -1) {
          // L'origin est ouvert
          if (newExpand.indexOf(newValue) === -1) {
            // L'origin est ouvert & la target est fermée
            newExpand.push(newValue);
            newExpand = newExpand.filter((el) => el !== oldValue);
          }
        } else {
          // L'origin est fermée
          if (newExpand.indexOf(newValue) > -1) {
            // L'origin est fermée & la target est ouverte
            newExpand.push(oldValue);
            newExpand = newExpand.filter((el) => el !== newValue);
          }
        }
        delete activeEditor.data.risk;
        delete next.risk;
        TemplateLib.editCategorie(activeEditor.data.id, {
          ...activeEditor.data,
          order: next.order,
        }).then(() => {
          TemplateLib.editCategorie(next.id, {
            ...next,
            order: activeEditor.data.order,
          }).then(() => {
            updateTree({
              ...result,
              selected: [
                projectIndex,
                activeEditor.root_key[1],
                activeEditor.root_key[2] + 1,
              ].join("-"),
              expanded: newExpand,
            });
            updateActiveEditor({
              ...activeEditor,
              root_key: [
                projectIndex,
                activeEditor.root_key[1],
                activeEditor.root_key[2] + 1,
              ],
              data: { ..._formData, order: next.order },
            });
            setloading(false);
          });
        });
      }
    }
  };

  const isDownButtonDisabled = () => {
    let isDisabled = true;
    if (activeEditor.root_key.length === 2) {
      isDisabled =
        result.projects[projectIndex].sections.length ===
        activeEditor.root_key[1] + 1;
    }
    if (activeEditor.root_key.length === 3) {
      isDisabled =
        result.projects[projectIndex].sections[activeEditor.root_key[1]]
          .sections?.length ===
        activeEditor.root_key[2] + 1;
    }
    return isDisabled;
  };

  const onParentCategoryChange = (id) => {
    const sisters = allCategories
      .filter((categorie) => categorie.category_id === id)
      .sort((a, b) => a.order - b.order);
    set_formData({
      ..._formData,
      category_id: id,
      order: sisters.at(-1).order + 1,
    });
  };

  const resetOrders = () => {
    if (
      Array.isArray(activeEditor.data.sections) &&
      activeEditor.data.sections.length > 0
    ) {
      set_loading(true);
      TemplateLib.editCategories(activeEditor.data.sections)
        .then((res) => {
          set_loading(false);
          updateFiltersData(() => ({
            ...filtersData,
          }));
          toast.success("Sauvegarde des ordres ok");
        })
        .catch((response) => {
          set_loading(false);
          config.handleErrorBackend({ response, message: "" });
        });
    }
  };

  const isSaveSubSectionsDisabled = () => {
    return (
      activeEditor.data.category_id !== null ||
      !Array.isArray(activeEditor.data.sections) ||
      (Array.isArray(activeEditor.data.sections) &&
        activeEditor.data.sections.length < 1)
    );
  };
  return (
    <Grid container spacing={2}>
      {loading ? (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CircularProgress size={20} />{" "}
        </Grid>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            marginBottom: "5px",
          }}
        >
          <div style={{ marginRight: "10px" }}>Ordre</div>
          <Button
            size="small"
            variant="contained"
            disabled={activeEditor.root_key.at(-1) - 1 === -1}
            onClick={OrderUp}
          >
            <ArrowUpwardIcon />
          </Button>
          <Button
            size="small"
            variant="contained"
            disabled={isDownButtonDisabled()}
            onClick={OrderDown}
          >
            <ArrowDownwardIcon />
          </Button>
          {activeEditor.data.category_id !== null && (
            <FormControl
              size="small"
              fullWidth
              variant="outlined"
              style={{ marginLeft: "10px" }}
            >
              <InputLabel>Section mère</InputLabel>
              <Select
                label="Section mère"
                value={_formData.category_id}
                onChange={(e) => {
                  onParentCategoryChange(e.target.value);
                }}
              >
                {categories.map((el, key) => (
                  <MenuItem key={el.id} value={el.id}>
                    {el.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
      )}
      <NameField
        _formData={_formData}
        set_formData={set_formData}
        label="Nom de la section"
      />
      <MandatoryField
        _formData={_formData}
        set_formData={set_formData}
        label={"Section facultative "}
      />
      {_formData.mandatory && (
        <Dependencies
          _formData={_formData}
          set_formData={set_formData}
          activeEditor={activeEditor}
        ></Dependencies>
      )}
      <SectionEditor _formData={_formData} set_formData={set_formData} />
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={resetOrders}
          disabled={isSaveSubSectionsDisabled()}
        >
          Enregistrer sous-sections
        </Button>
        <Delete _function={deleteSection} loading={_loading} />
        <Modify _function={editSection} loading={_loading} />
      </Grid>
    </Grid>
  );
}
