import DocumentContext from "../../../providers/DocumentContext"
import TemplateTaskLib from "@common/libs/template-lib"
import TemplateLib from "@common/libs/interview-template-lib"
import {
  Button,
  CircularProgress,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core/"
import { makeStyles } from "@material-ui/core/styles"
import React, { useState, useContext } from "react"
import config from "~/config"
import TemplateLibInterview from "@common/libs/interview-template-lib"
import { toast } from "react-toastify"

const useStyles = makeStyles(({ palette }) => ({
  button: {
    marginTop: 20,
    height: 60,
    fontSize: 18,
    fontWeight: "bold",
    color: "white",
    backgroundColor: palette.cta.dark,
    "&:hover": {
      backgroundColor: palette.cta.main,
    },
    "&:disabled": {
      backgroundColor: "silver",
      color: "grey",
    },
  },

  project: {
    margin: "5px auto",
    padding: "4px 9px",
    borderRadius: 5,
    fontWeight: "bold",
    textAlign: "center",
    maxWidth: 1200,
  },

  wordButton: {
    display: "flex",
    alignItems: "center",
  },
}))

export default function PdfMapping() {
  const classes = useStyles()
  const { document, updateDocument } = useContext(DocumentContext)

  if (!document.pdf_mapping) {
    document.pdf_mapping = []
  } else {
    // setFields(document.pdf_mapping)
  }

  console.log("document.pdf_mapping", document, document.pdf_mapping)

  const [fields, setFields] = useState(document.pdf_mapping)

  const [projectName, setprojectName] = useState(null)
  const [taskName, settaskName] = useState(null)

  let [loading, setLoading] = useState(false)

  function updatePdfMapping(updatedField, value) {
    console.log("updatePdfMapping", updatedField, value)
    // field.value = value

    const nextFields = fields.map((field) => {
      if (updatedField == field) {
        field.value = value
      }
      return field
    })

    setFields(nextFields)
    updateDocument({ ...document, pdf_mapping: nextFields })
    TemplateLib.updateDocument(document.id, document).then((res) => {
      // updateDocument({ ...document, launchSave: res.id })
    })
    console.log("updatePdfMapping2", updatedField, value)
  }

  React.useEffect(() => {
    if (document.task) {
      TemplateTaskLib.getSpecificTask(document.task)
        .then((res) => {
          settaskName(res.name)
        })
        .catch(() => settaskName("/"))
    } else settaskName("/")

    TemplateTaskLib.getProject(document.project)
      .then((res) => {
        setprojectName(res.name)
      })
      .catch(() => settaskName("/"))
  }, [document.project])

  console.log("need to load fields?", document.pdf_mapping)
  if (document.pdf_mapping.length == 0) {
    // console.log("loading fields")
    // TemplateLibInterview.getPdfFields(document.id)
    //   .then((res) => {
    //     console.log("pdf fields", res)
    //     document.pdf_mapping = res
    //     setFields(res)
    //     updateDocument({ ...document, pdf_mapping: res })
    //     TemplateLib.updateDocument(document.id, document).then((res) => {
    //       // updateDocument({ ...document, launchSave: res.id })
    //     })
    //   })
    //   .catch((e) => console.log("e", e))
  } else {
    console.log("already have fields")
  }

  React.useEffect(() => {
    // TemplateLibInterview.getPdfFields(document.id)
    //   .then((res) => {
    //     console.log("pdf fields", res)
    //     document.pdf_mapping = res
    //     listItems = document.pdf_mapping.map((field) => (
    //       <li>{field.field_name}</li>
    //     ))
    //     setFields(res)
    //   })
    //   .catch((e) => console.log("e", e))
  }, [document.pdf_mapping])

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: 450,
        marginBottom: 40,
        marginTop: -20,
      }}
    >
      {document.pdf && (
        <div
          style={{
            width: "80%",
          }}
        >
          <h3>Mapping formulaire PDF</h3>
          {fields.map((el, key) => (
            <div key={key}>
              <FormControl
                fullWidth
                label="Titre du document"
                size="small"
                style={{ backgroundColor: "white" }}
              >
                <span>{el.field_name}</span>
                <OutlinedInput
                  style={{ fontSize: 14 }}
                  value={el.value}
                  size={160}
                  fullWidth={true}
                  onChange={(e) => updatePdfMapping(el, e.target.value)}
                />
              </FormControl>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
