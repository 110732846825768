import { ApiServiceRaw } from '../services/api-service';

const ApiService = new ApiServiceRaw(process.env.COMMON_LAW_TEMPLATE_API_URL);

export default class TemplateLib {
  /////////////////// DOCUMENTS

  static DocumentPath = '/api/templates/{id}/';
  static DocumentOptimizedPath = '/api/templates_optimized/{id}/';
  static DocumentUpdatePath = '/api/templates/{id}/update_content/';
  static DocumentDownloadPath = '/api/templates/{id}/download_content';
  static DocumentIndentedDownloadPath = '/api/templates/{id}/download_indented_content';
  static DocumentAnnotatedPdfDownloadPath = '/api/templates/{id}/download_annotated_pdf_content';
  static PdfFieldsPath = '/api/templates/{id}/get_pdf_fields';

  static async createDocumentDocx(id, document) {
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      const formData = new FormData();
      formData.append('file', document);
      const response = await ApiService.post(
        TemplateLib.DocumentUpdatePath.replace('{id}', id),
        formData,
        config,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getDocumentsFromProject(project_id) {
    const path = TemplateLib.DocumentPath.replace('{id}/', '');
    const response = await ApiService.get(path, { params: { project__id: project_id } });
    return response.data.results;
  }

  static async getDocumentsFromTask(task_id) {
    const path = TemplateLib.DocumentPath.replace('{id}/', '');
    const response = await ApiService.get(path, { params: { task__id: task_id } });
    return response.data.results;
  }

  static async getDocumentDocx(id) {
    const path = TemplateLib.DocumentDownloadPath.replace('{id}', id); // +'?asfile=true';

    const response = await ApiService.get(path, {
      responseType: 'blob',
    });

    return response.data;
  }

  static async downloadDocumentDocx(id) {
    const path = TemplateLib.DocumentDownloadPath.replace('{id}', id); // +'?asfile=true';

    const response = await ApiService.download(path);
    return response.data;
  }

  static async downloadIndentedDocumentDocx(id) {
    const path = TemplateLib.DocumentIndentedDownloadPath.replace('{id}', id); // +'?asfile=true';

    const response = await ApiService.download(path);
    return response.data;
  }

  static async downloadAnnotatedPdfFile(id) {
    const path = TemplateLib.DocumentAnnotatedPdfDownloadPath.replace('{id}', id); // +'?asfile=true';

    const response = await ApiService.download(path);

    return response.data;
  }

  static async getDocument(id) {
    const path = TemplateLib.DocumentPath.replace('{id}', id);
    const response = await ApiService.get(path);
    return response.data;
  }

  static async getPdfFields(id) {
    const path = TemplateLib.PdfFieldsPath.replace('{id}', id);
    const response = await ApiService.get(path);
    return response.data;
  }

  static async getAllDocument(site = '') {
    const response = await ApiService.get(
      TemplateLib.DocumentPath.replace('{id}/', '') + (site !== '' ? '?set_site=' + site : ''),
      {
        params: {
          all_templates: true,
          limit: 10000,
        },
      },
    );
    return response.data.results;
  }

  static async getAllDocumentsOptimized(site = '') {
    const response = await ApiService.get(
      TemplateLib.DocumentOptimizedPath.replace('{id}/', '') +
        (site !== '' ? '?set_site=' + site : ''),
      {
        params: {
          all_templates: true,
          limit: 10000,
        },
      },
    );
    return response.data.results;
  }

  static async createDocument(object, site) {
    const response = await ApiService.post(
      TemplateLib.DocumentPath.replace('{id}/', '') + '?set_site=' + site,
      object,
    );

    return response.data;
  }

  static async readDocument(id) {
    const path = TemplateLib.DocumentPath.replace('{id}', id);

    const response = await ApiService.get(path);

    return response.data.name;
  }

  static async updateDocument(id, object) {
    const path = TemplateLib.DocumentPath.replace('{id}', id);

    const response = await ApiService.put(path, object);
    return response.data;
  }

  static async deleteDocument(id) {
    const path = TemplateLib.DocumentPath.replace('{id}', id);

    const response = await ApiService.delete(path);

    return response;
  }

  static async copyDocument({ id, project_id }) {
    const path = TemplateLib.DocumentPath.replace('{id}', id) + 'duplicate/';

    const response = await ApiService.post(path, { project: project_id });

    return response;
  }

  static async moveDocument({ id, project_id }) {
    const path = TemplateLib.DocumentPath.replace('{id}', id) + 'move/';

    const response = await ApiService.put(path, { project: project_id });

    return response;
  }

  /////////////////// VARIABLES

  static VariablePath = '/api/variables/{id}/';
  static VariableFromProjectPath = '/api/variables';

  static async getVariablesFromDocument(doc_id, site) {
    const path = TemplateLib.VariableFromProjectPath;
    const response = await ApiService.get(path + '?limit=10000&set_site=' + site, {
      params: { assigned_documents__id: doc_id },
    });
    return response.data.results;
  }

  static async getVariablesFromProject(project_id, site) {
    const path = TemplateLib.VariableFromProjectPath;
    const response = await ApiService.get(path + '?limit=10000&set_site=' + site, {
      params: { project__id: project_id },
    });
    return response.data.results;
  }

  static async getVariable(id) {
    const path = TemplateLib.VariablePath.replace('{id}', id);
    const response = await ApiService.get(path);
    return response.data;
  }

  static async assignVariable(variable_id, assigned_documents) {
    const path = TemplateLib.VariablePath.replace('{id}', variable_id) + 'assign/';

    const response = await ApiService.put(
      path,
      assigned_documents.map((el) => ({ pk: el })),
    );

    return response;
  }

  static async getAllVariable() {
    const response = await ApiService.get(TemplateLib.VariablePath.replace('{id}/', ''));
    return response.data.results;
  }

  static async getUnusedLocalVariable(project) {
    const response = await ApiService.get(
      `${TemplateLib.VariableFromProjectPath}/get_unused_local_variables?project_id=${project}`,
    );
    return response.data;
  }

  static async getUnusedGlobalVariable(project) {
    const response = await ApiService.get(
      `${TemplateLib.VariableFromProjectPath}/get_unused_global_variables?project_id=${project}`,
    );
    return response.data;
  }

  static async createVariable(object) {
    const response = await ApiService.post(TemplateLib.VariablePath.replace('{id}/', ''), object);

    return response.data;
  }

  static async readVariable(id) {
    const path = TemplateLib.VariablePath.replace('{id}', id);

    const response = await ApiService.get(path);

    return response.data.name;
  }

  static async updateVariable(id, object) {
    try {
      const path = TemplateLib.VariablePath.replace('{id}', id);
      const response = await ApiService.put(path, object);
      return response.data;
    } catch (e) {
      console.error(e);
    }
  }

  static async deleteVariable(id) {
    const path = TemplateLib.VariablePath.replace('{id}', id);

    const response = await ApiService.delete(path);

    return response;
  }

  /////////////////// GROUPS VARIABLES

  static GroupVariablesPath = '/api/groups/{id}/';

  static async getGroupVariablesFromProject(project_id, site) {
    const path =
      TemplateLib.GroupVariablesPath.replace('{id}/', '') + '?limit=10000&set_site=' + site;
    const response = await ApiService.get(path, { params: { project__id: project_id } });
    return response.data.results;
  }

  static async getGroupVariable(id) {
    const path = TemplateLib.GroupVariablesPath.replace('{id}', id);
    const response = await ApiService.get(path);
    return response.data;
  }

  static async getAllGroupVariable() {
    const response = await ApiService.get(TemplateLib.GroupVariablesPath.replace('{id}/', ''));
    return response.data.results;
  }

  static async createGroupVariable(object) {
    const response = await ApiService.post(
      TemplateLib.GroupVariablesPath.replace('{id}/', ''),
      object,
    );

    return response.data;
  }

  static async readGroupVariable(id) {
    const path = TemplateLib.GroupVariablesPath.replace('{id}', id);

    const response = await ApiService.get(path);

    return response.data.name;
  }

  static async updateGroupVariable(id, object) {
    const path = TemplateLib.GroupVariablesPath.replace('{id}', id);

    const response = await ApiService.put(path, object);

    return response.data;
  }

  static async deleteGroupVariable(id) {
    const path = TemplateLib.GroupVariablesPath.replace('{id}', id);

    const response = await ApiService.delete(path);

    return response;
  }
}
