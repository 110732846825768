import TemplateLibInterview from "@common/libs/interview-template-lib"
import {
  TextField,
  Button,
  CircularProgress,
  Checkbox,
  Grid,
} from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import React, { useState, useContext, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import ActiveEditorContext from "~/providers/ActiveEditorContext"
import FiltersContext from "~/providers/FiltersContext"

export default function Questionnaire(props) {
  const { _formData, set_formData, save_function } = props
  const history = useHistory()
  const { activeEditor } = useContext(ActiveEditorContext)
  const { filtersData } = useContext(FiltersContext)

  const [listDocuments, setlistDocuments] = useState(null)

  useEffect(() => {
    let state = {
      text: _formData.actions[0].data.text,
      interview_id: _formData.actions[0].data.interview_id
        ? _formData.actions[0].data.interview_id
        : [],
    }
    set_formData({
      ..._formData,
      interview_id: undefined,
      actions: [{ ..._formData.actions[0], data: state }],
    })

    if (activeEditor?.data?.id)
      TemplateLibInterview.getDocumentsFromTask(activeEditor.data.id).then(
        (docs) => {
          TemplateLibInterview.getDocumentsFromProject(
            activeEditor.root[0].id
          ).then((res) => {
            if (activeEditor.data)
              setlistDocuments(
                res.filter(
                  (el) => el.task === null || el.task === activeEditor.data.id
                )
              )
            else setlistDocuments(res.filter((el) => el.task === null))
            let result = { ..._formData }
            result.actions[0].data.interview_id = docs.map((el) => el.id)
            set_formData(result)
          })
        }
      )
    else
      TemplateLibInterview.getDocumentsFromProject(
        activeEditor.root[0].id
      ).then((res) => {
        if (activeEditor.data)
          setlistDocuments(
            res.filter(
              (el) => el.task === null || el.task === activeEditor.data.id
            )
          )
        else setlistDocuments(res.filter((el) => el.task === null))
      })
  }, [])

  const makeid = (length) => {
    var result = ""
    var characters = "0123456789"
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  const addDocument = () => {
    TemplateLibInterview.createDocument(
      {
        project: activeEditor.root[0].id,
        name: "Nouveau document - " + makeid(5),
      },
      filtersData.site.domain
    )
      .then((res) => {
        let result = { ..._formData }
        result.actions[0].data.interview_id.push(res.id)
        set_formData(result)
        return res
      })
      .then((res) => {
        save_function()
        return res
      })
      .then((res) => {
        TemplateLibInterview.updateDocument(res.id, {
          project: res.project,
          name: res.name,
          task: activeEditor.data.id,
        }).then(() => {
          toast.success("Modèle de document créé")
          history.push("/documents/" + res.id)
        })
      })
  }

  return (
    <div>
      {listDocuments === null ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress size={20} />
        </div>
      ) : (
        <div style={{ display: "flex" }}>
          <Autocomplete
            multiple
            options={listDocuments.map((el) => el.id)}
            getOptionLabel={(option) => {
              const doc = listDocuments.find((el) => el.id === option)
              return doc?.name + " (id: " + doc?.id + ")"
            }}
            fullWidth
            size="small"
            renderInput={(params) => (
              <TextField
                variant="outlined"
                style={{ backgroundColor: "white" }}
                {...params}
                label="Document généré"
                variant="outlined"
              />
            )}
            value={_formData.actions[0].data.interview_id || []}
            onChange={(e, value) => {
              let result = { ..._formData }
              result.actions[0].data.interview_id = value
              set_formData(result)
            }}
          />
          <Button
            disabled={
              _formData.actions[0].data.interview_id === undefined ||
              _formData.actions[0].data.interview_id.length !== 1
            }
            variant="contained"
            color="primary"
            style={{ marginLeft: 10, width: 200 }}
            onClick={() =>
              history.push(
                "/documents/" + _formData.actions[0].data.interview_id[0]
              )
            }
          >
            Modifier document
          </Button>
        </div>
      )}

      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Checkbox
          checked={_formData.multi_documents}
          onChange={(e) =>
            set_formData({ ..._formData, multi_documents: e.target.checked })
          }
        />
        <span
          style={{ cursor: "pointer" }}
          onClick={() =>
            set_formData({
              ..._formData,
              multi_documents: !_formData.multi_documents,
            })
          }
        >
          Autoriser la génération de plusieurs documents
        </span>
      </Grid>

      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Checkbox
          checked={_formData.composable}
          onChange={(e) =>
            set_formData({ ..._formData, composable: e.target.checked })
          }
        />
        <span
          style={{ cursor: "pointer" }}
          onClick={() =>
            set_formData({ ..._formData, composable: !_formData.composable })
          }
        >
          Document composable
        </span>
      </Grid>

      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Checkbox
          checked={_formData.composite}
          onChange={(e) =>
            set_formData({ ..._formData, composite: e.target.checked })
          }
        />
        <span
          style={{ cursor: "pointer" }}
          onClick={() =>
            set_formData({ ..._formData, composite: !_formData.composite })
          }
        >
          Document composite
        </span>
      </Grid>

      <div
        style={{ display: "flex", justifyContent: "center", margin: "20px 0" }}
      >
        <Button
          style={{ width: 400 }}
          variant="contained"
          color="primary"
          onClick={addDocument}
          disabled={_formData.name === ""}
        >
          + Ajouter un nouveau document pour ce projet
        </Button>
      </div>
    </div>
  )
}
