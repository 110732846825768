import DocumentContext from "../../providers/DocumentContext"
import DialogEditor from "./EditorView/DialogEditor"
import EditorComponent from "./EditorView/EditorComponent"
import uploadMachineDoc from "./EditorView/functions/uploadMachineDoc"
import uploadNewDoc from "./EditorView/functions/uploadNewDoc"
import "./EditorView/utils/anim.css"
import ListVariables from "./ListVariables"
import Summary from "./Summary/index"
import TemplateLib from "@common/libs/interview-template-lib"
import { Paper, Button, CircularProgress, Fade } from "@material-ui/core/"
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import AssignmentIcon from "@material-ui/icons/Assignment"
import DescriptionIcon from "@material-ui/icons/Description"
import GetAppIcon from "@material-ui/icons/GetApp"
import ListAltIcon from "@material-ui/icons/ListAlt"
import React, { useState, useContext } from "react"
import { Prompt } from "react-router"
import { useLocation, useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import FiltersContext from "~/providers/FiltersContext"
import TemplateLibInterview from "@common/libs/interview-template-lib"

import checkOrder from "./ListVariables/checkOrder"
const useStyles = makeStyles(() => ({
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
    fontSize: 18,
    borderBottom: " 1px solid silver",
  },
  container: {
    minHeight: 530,
    padding: " 10px 0",
    overflowY: "scroll",
  },
  editor: {
    backgroundColor: "white",
    width: "80%",
    margin: "20px 10%",
    height: "600px",
    padding: 15,
  },
  placeholder: {
    backgroundColor: "white",
    width: "80%",
    margin: "0 10%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "20px 10px",
  },
}))

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      // maxWidth: 80,
      width: "80%",
      backgroundColor: "white",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />)

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#black",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />)

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function Document({ tabIndex, setTabIndex }) {
  const classes = useStyles()
  const { document, updateDocument } = useContext(DocumentContext)
  const { filtersData } = useContext(FiltersContext)
  const [loading, setloading] = useState(false)
  const [open, setOpen] = React.useState(false)

  const history = useHistory()
  const location = useLocation()

  React.useEffect(() => {
    const id = +location.pathname.split("/")[2]
    setloading(true)
    TemplateLib.getDocument(id).then((doc) => {
      TemplateLib.getGroupVariablesFromProject(
        doc.project,
        filtersData?.site ? filtersData.site.domain : ""
      ).then((groupsVariables) => {
        TemplateLib.getVariablesFromProject(
          doc.project,
          filtersData?.site ? filtersData.site.domain : ""
        ).then((varsProject) => {
          const variables = varsProject.filter(
            (e) => e.is_global === true || e.documents.find((y) => y === +id)
          )

          checkOrder({ toOrder: groupsVariables, isGroup: true }).then(
            (orderedGroup) => {
              Promise.all(
                [...groupsVariables, { id: null }].map((el) =>
                  checkOrder({
                    toOrder: variables.filter((e) => e.group === el.id),
                    isGroup: false,
                  })
                )
              ).then((orderedVars) => {
                const copyDocument = {
                  ...document,
                  ...doc,
                  groupsVariables: orderedGroup,
                  variables: orderedVars.flat(),
                }
                TemplateLib.getDocumentDocx(doc.id)
                  .then((ress) => {
                    var reader = new FileReader()
                    reader.readAsArrayBuffer(ress)
                    reader.onload = function () {
                      const newFile = new File([reader.result], "file.docx", {
                        type: "application/octet-stream",
                      })

                      uploadMachineDoc({
                        setTabIndex,
                        copyDocument,
                        updateDocument,
                        setloading,
                        newFile,
                      })
                    }
                    reader.onerror = function (error) {
                      console.error("Error: ", error)
                    }
                  })
                  .catch(() => {
                    updateDocument(copyDocument)
                    setloading(false)
                  })
              })
            }
          )
        })
      })
    })
  }, [])

  const [togglePrompt, settogglePrompt] = React.useState(true)

  const unload = (event) => {
    const isInDocument =
      event.currentTarget.location.href.indexOf("documents/") > -1
    if (isInDocument) {
      event.returnValue = true
    }
  }

  React.useEffect(() => {
    if (document.launchSave === false) {
      settogglePrompt(false)
      toast.success("Modèle de document sauvegardé")
      // setTimeout(() => {
      //     history.push("/documents");
      // }, 100);
    }
  }, [document.launchSave])

  return (
    <Paper style={{ maxWidth: "80vw" }}>
      <StyledTabs
        value={tabIndex}
        onChange={(e, v) => {
          setTabIndex(v)
        }}
      >
        <StyledTab
          disabled={document.main ? true : false}
          label={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: document.main && "#999",
              }}
            >
              <GetAppIcon style={{ marginRight: 5 }} />
              <span>Document</span>
            </div>
          }
          index={0}
          style={{
            backgroundColor: tabIndex === 0 ? "#635ee7" : "#fff",
            color: tabIndex === 0 ? "white" : document.main ? "black" : "#666",
          }}
        />
        <StyledTab
          label={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <AssignmentIcon style={{ marginRight: 5 }} />
              <span>Intégration</span>
            </div>
          }
          index={1}
          disabled={document.main ? false : true}
          style={{
            backgroundColor: tabIndex === 1 ? "#635ee7" : "#fff",
            color: tabIndex === 1 ? "white" : document.main ? "black" : "#666",
          }}
        />
        <StyledTab
          label={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ListAltIcon style={{ marginRight: 5 }} />
              <span>Pagination du questionnaire</span>
            </div>
          }
          index={2}
          disabled={document.main ? false : true}
          style={{
            backgroundColor: tabIndex === 2 ? "#635ee7" : "#fff",
            color: tabIndex === 2 ? "white" : document.main ? "black" : "#666",
          }}
        />
      </StyledTabs>
      <hr style={{ margin: 0 }} />
      <TabPanel value={tabIndex} index={0}>
        <div
          className={classes.container}
          style={{
            backgroundColor: document.main === null ? "#bbb" : "#dfdfdf",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "450px",
            }}
          >
            <Paper elevation={6} className={classes.placeholder}>
              {!loading ? (
                <>
                  <input
                    hidden
                    type="file"
                    id="documentFileDocx"
                    accept=".doc,.docx,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    onChange={(e) => {
                      setloading(true)
                      const input =
                        window.document.getElementById("documentFileDocx")
                      TemplateLibInterview.createDocumentDocx(
                        document.id,
                        input.files[0]
                      )
                        .then((result) => {
                          console.log("uploaded document2", result)
                          document.pdf = result.pdf
                          document.pdf_mapping = result.pdf_mapping
                          // updateDocument({ ...result, launchSave: false })
                          console.log("document updated")
                          input.value = ""
                          toast.success("Le fichier a bien été créé.")
                          setloading(false)
                          setTabIndex(1)
                        })
                        .catch((error) => {
                          input.value = ""
                          toast.error(error.response.data.errors)
                          setloading(false)
                        })
                    }}
                  />
                  <label htmlFor="documentFileDocx">
                    <Button
                      id="import_document_button"
                      style={{ marginBottom: 15, marginTop: 15 }}
                      variant="contained"
                      component="span"
                      color="primary"
                    >
                      Importer Document ( .docx / .pdf )
                    </Button>
                  </label>
                </>
              ) : (
                <CircularProgress size={28} />
              )}
            </Paper>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <Summary />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <ListVariables />
      </TabPanel>
      <DialogEditor open={open} setOpen={setOpen} />
      <input
        hidden
        type="file"
        id="update_document_button"
        accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        onChange={(e) => {
          const newFile = e.target.files[e.target.files.length - 1]
          uploadMachineDoc({
            setTabIndex,
            copyDocument: document,
            updateDocument,
            setloading,
            newFile,
          })
        }}
      />
    </Paper>
  )
}
