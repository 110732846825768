import {
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  Grid,
  InputLabel,
  TextField,
  Button,
  Divider,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import RemoveIcon from "@material-ui/icons/Remove";
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import React, { useState } from "react";
import SelectObjectModelField from "../SelectObjectModelField";
import SelectObjectModel from "../SelectObjectModel";
import MappingComplexInput from "../MappingComplexInput";
import RolesInput from "../RolesInput";
import ObjectChoice from "./ObjectChoice";
import ObjectMutliChoice from "./ObjectMutliChoice";

export default ({
  variableDialog,
  setvariableDialog,
  setoriginalvariableType,
  originalvariableType,
}) => {
  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const [databaseObjectModelName, setDatabaseObjectModelName] = useState("");
  const [showChoice, setShowChoice] = useState(false);
  const [showAddChoice, setShowAddChoice] = useState(false);

  React.useEffect(() => {
    const id = makeid(8);
    if (originalvariableType !== "object") {
      setvariableDialog({
        ...variableDialog,
        options: [{ id, label: "", type: "text" }],
        placeholder: [{ id, value: "" }],
      });
      setoriginalvariableType("object");
    }
  }, []);

  return (
    originalvariableType === "object" && (
      <>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{
            backgroundColor: "#eee",
            border: "1px solid silver",
          }}
        >
          <SelectObjectModel
            variableDialog={variableDialog}
            setvariableDialog={setvariableDialog}
            index={0}
          ></SelectObjectModel>

          <FormControl
            size="small"
            style={{
              width: 300,
              backgroundColor: "white",
              marginLeft: 15,
              marginRight: 15,
            }}
            variant="outlined"
          >
            <InputLabel>Type de mapping</InputLabel>
            <Select
              label="Type de mapping"
              value={
                variableDialog.database_object_model_fields_mapping[0]
                  .mappingType
              }
              onChange={(e) => {
                let result = { ...variableDialog };
                result.database_object_model_fields_mapping[0].mappingType =
                  e.target.value;
                setvariableDialog(result);
              }}
            >
              <MenuItem value={"simple"}>Mono-sélection</MenuItem>
              {/* <MenuItem value={"address"}>Adresse</MenuItem> */}
              <MenuItem value={"list_with_single_choice"}>
                Mono-sélection dans une liste
              </MenuItem>
              <MenuItem value={"list_with_multiple_choices"}>
                Multi-sélection dans une liste
              </MenuItem>
            </Select>
          </FormControl>
          <RolesInput
            variableDialog={variableDialog}
            setvariableDialog={setvariableDialog}
          ></RolesInput>

          <Divider
            style={{
              marginTop: 10,
              marginBottom: 10,
            }}
          />
          {variableDialog.options &&
            variableDialog.options?.map((el, key) => (
              <div>
                <div
                  key={key}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 5,
                  }}
                >
                  <SubdirectoryArrowRightIcon />
                  <TextField
                    style={{
                      backgroundColor: "white",
                      marginLeft: 10,
                      marginRight: 10,
                      width: 400,
                    }}
                    label={"Element " + (key + 1)}
                    size="small"
                    fullWidth
                    variant="outlined"
                    type="text"
                    value={variableDialog.options[key].label}
                    onChange={(e) => {
                      let result = { ...variableDialog };
                      result.options[key].label = e.target.value;
                      setvariableDialog(result);
                    }}
                  />

                  {/* <SelectObjectModelField
                    variableDialog={variableDialog}
                    setvariableDialog={setvariableDialog}
                    elementKey={key}
                    tabValue={1}
                  ></SelectObjectModelField> */}

                  <MappingComplexInput
                    style={{
                      backgroundColor: "white",
                      width: "100%",
                    }}
                    variableFieldName={variableDialog.options[key].label}
                    variableFieldKey={key}
                    objectNumber={0}
                    variableDialog={variableDialog}
                    setvariableDialog={setvariableDialog}
                  ></MappingComplexInput>

                  <FormControl
                    size="small"
                    style={{
                      width: 200,
                      backgroundColor: "white",
                      marginLeft: 10,
                    }}
                    variant="outlined"
                  >
                    <InputLabel>Type</InputLabel>
                    <Select
                      label="Type"
                      value={variableDialog.options[key].type}
                      onChange={(e) => {
                        let result = { ...variableDialog };
                        result.options[key].type = e.target.value;
                        result.options[key].showAddChoice =
                          e.target.value === "choice";
                        result.options[key].showAddMulti =
                          e.target.value === "multi";
                        setvariableDialog(result);
                      }}
                    >
                      <MenuItem value={"text"}>Texte</MenuItem>
                      <MenuItem value={"number"}>Nombre</MenuItem>
                      <MenuItem value={"bool"}>Oui / Non</MenuItem>
                      <MenuItem value={"date"}>Date</MenuItem>
                      <MenuItem value={"choice"}>Choix</MenuItem>
                      <MenuItem value={"multi"}>Choix multiple</MenuItem>
                    </Select>
                  </FormControl>
                  {el.showAddChoice ? (
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      style={{ marginLeft: 10 }}
                      onClick={() => {
                        let result = { ...variableDialog };
                        result.options[key].showChoice =
                          !result.options[key].showChoice;
                        setvariableDialog(result);
                      }}
                    >
                      {el.showChoice ? <RemoveIcon /> : <EditIcon />}
                    </Button>
                  ) : null}

                  {el.showAddMulti ? (
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      style={{ marginLeft: 10 }}
                      onClick={() => {
                        let result = { ...variableDialog };
                        result.options[key].showMulti =
                          !result.options[key].showMulti;
                        setvariableDialog(result);
                      }}
                    >
                      {el.showChoice ? <RemoveIcon /> : <EditIcon />}
                    </Button>
                  ) : null}

                  {el.type === "bool" ? (
                    <div
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: 10,
                        width: 300,
                      }}
                      onClick={() => {
                        let result = { ...variableDialog };
                        result.placeholder[key].value = Boolean(
                          !result.placeholder[key].value
                        );
                        setvariableDialog(result);
                      }}
                    >
                      Cochée par défaut
                      <Checkbox
                        checked={variableDialog.placeholder[key].value}
                      />
                    </div>
                  ) : (
                    <TextField
                      label={el.type === "date" ? false : "Valeur pré-remplie"}
                      value={variableDialog.placeholder[key].value}
                      onChange={(e) => {
                        let result = { ...variableDialog };
                        result.placeholder[key].value =
                          el.type === "number"
                            ? +e.target.value
                            : e.target.value;
                        setvariableDialog(result);
                      }}
                      type={el.type}
                      variant="outlined"
                      size="small"
                      style={{
                        marginLeft: 10,
                        backgroundColor: "white",
                        width: 300,
                      }}
                    />
                  )}
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    disabled={key === 0}
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      let result = { ...variableDialog };
                      result.options = result.options.filter(
                        (option) => option.id !== el.id
                      );
                      result.placeholder = result.placeholder.filter(
                        (option) => option.id !== el.id
                      );
                      result.database_object_model_fields_mapping[0].data =
                        result.database_object_model_fields_mapping[0].data.filter(
                          (option) => option.variable_field_name !== el.label
                        );
                      setvariableDialog(result);
                    }}
                  >
                    <RemoveIcon />
                  </Button>
                </div>
                <div>
                  {el.showChoice ? (
                    <ObjectChoice
                      variableDialog={variableDialog}
                      optionKey={key}
                      setvariableDialog={setvariableDialog}
                    ></ObjectChoice>
                  ) : null}

                  {el.showMulti ? (
                    <ObjectMutliChoice
                      variableDialog={variableDialog}
                      optionKey={key}
                      setvariableDialog={setvariableDialog}
                    ></ObjectMutliChoice>
                  ) : null}
                </div>
              </div>
            ))}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              paddingBottom: 10,
              paddingTop: 20,
            }}
          >
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => {
                let result = { ...variableDialog };
                const id = makeid(8);
                result.options.push({ id, label: "", type: "text" });
                result.placeholder.push({ id, value: "" });
                result.database_object_model_fields_mapping[0].data.push({
                  mapping_complex: "",
                  database_object_model_field_name: "",
                  variable_field_name: "",
                });
                setvariableDialog(result);
              }}
            >
              <AddIcon style={{ fontSize: 18, marginRight: 10 }} /> Ajouter
              élément
            </Button>
          </div>
        </Grid>
      </>
    )
  );
};
