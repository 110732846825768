import {
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  Grid,
  InputLabel,
  TextField,
  Button,
  Box,
  Divider,
  Tab,
} from "@material-ui/core";
import { TabPanel, TabContext, TabList } from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ArrayMultiObjectsDialog from "../ArrayMultiObjectsDialog";
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import { useEffect, useState } from "react";
import SelectObjectModelField from "../SelectObjectModelField";
import SelectObjectModel from "../SelectObjectModel";
import RolesInput from "../RolesInput";
import MappingComplexInput from "../MappingComplexInput";

export default ({
  variableDialog,
  setvariableDialog,
  setoriginalvariableType,
  originalvariableType,
}) => {
  const [tabValue, setTabValue] = useState("1");
  const [arrayMultiObjectsDialog, setArrayMultiObjectsDialog] = useState(false);
  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  useEffect(() => {
    if (originalvariableType !== "multiarray") {
      console.log("resetting as multiarray");
      variableDialog.options = [];
      variableDialog.placeholder = [];
      setvariableDialog({
        ...variableDialog,
        options: [],
        placeholder: [],
      });
      setoriginalvariableType("multiarray");
    }

    console.log("checking mapping", variableDialog, tabValue);

    if (
      variableDialog.options.length !=
      variableDialog.database_object_model_fields_mapping.length
    ) {
      console.log("creating new mappings");
      for (
        let i = 0;
        i <
        variableDialog.options.length -
          variableDialog.database_object_model_fields_mapping.length;
        i++
      ) {
        console.log("creating mapping");
        variableDialog.database_object_model_fields_mapping.push({
          database_object_model_name: "",
          mappingType: "simple",
          data: [
            {
              mapping_complex: "",
              database_object_model_field_name: "",
              variable_field_name: "",
            },
          ],
        });
        setvariableDialog({
          ...variableDialog,
        });
      }
    }

    if (
      variableDialog.database_object_model_fields_mapping[tabValue - 1] ===
      undefined
    ) {
      variableDialog.database_object_model_fields_mapping.push({
        database_object_model_name: "",
        mappingType: "simple",
        data: [
          {
            mapping_complex: "",
            database_object_model_field_name: "",
            variable_field_name: "",
          },
        ],
      });
      setvariableDialog({
        ...variableDialog,
      });
    }
    if (
      variableDialog.database_object_model_fields_mapping[+tabValue - 1]
        .data[0] === undefined
    ) {
      variableDialog.database_object_model_fields_mapping[
        +tabValue - 1
      ].data.push({
        mapping_complex: "",
        database_object_model_field_name: "",
        variable_field_name: "",
      });
      setvariableDialog({
        ...variableDialog,
      });
    }
  }, []);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    originalvariableType === "multiarray" && (
      <>
        <ArrayMultiObjectsDialog
          variableDialog={variableDialog}
          setvariableDialog={setvariableDialog}
          arrayMultiObjectsDialog={arrayMultiObjectsDialog}
          setArrayMultiObjectsDialog={setArrayMultiObjectsDialog}
        />
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{
            backgroundColor: "#eee",
            border: "1px solid silver",
          }}
        >
          {variableDialog.options.length === 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bold",
                paddingBottom: 10,
                paddingTop: 10,
              }}
            >
              <Button
                onClick={() => {
                  setArrayMultiObjectsDialog(true);
                }}
                color="primary"
                variant="contained"
                style={{ margin: "5px" }}
              >
                <AddIcon /> Ajouter un objet
              </Button>
            </div>
          )}
          {variableDialog.options.length > 0 && (
            <Box sx={{ width: "100%", typography: "body1" }}>
              <Checkbox
                checked={variableDialog.is_mail_merging}
                onChange={(e) => {
                  let result = { ...variableDialog };
                  result.is_mail_merging = e.target.checked;
                  setvariableDialog(result);
                }}
              ></Checkbox>
              <span>Variable de publipostage</span>

              <Divider
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                }}
              />
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    {variableDialog.options.map((object, key) => (
                      <Tab
                        label={object[0].label}
                        value={`${key + 1}`}
                        key={key}
                      />
                    ))}
                    <Button
                      onClick={() => {
                        setArrayMultiObjectsDialog(true);
                      }}
                      style={{ margin: "5px", padding: "5px 10px" }}
                      color="primary"
                      variant="contained"
                    >
                      <AddIcon />
                    </Button>
                  </TabList>
                </Box>
                <TabPanel
                  value={`${tabValue}`}
                  style={{
                    padding: 10,
                  }}
                >
                  <SelectObjectModel
                    variableDialog={variableDialog}
                    setvariableDialog={setvariableDialog}
                    index={tabValue - 1}
                  ></SelectObjectModel>

                  <FormControl
                    size="small"
                    style={{
                      width: 200,
                      backgroundColor: "white",
                      marginLeft: 15,
                      marginRight: 15,
                    }}
                    variant="outlined"
                  >
                    <InputLabel>Type de mapping</InputLabel>
                    <Select
                      label="Type de mapping"
                      value={
                        variableDialog.database_object_model_fields_mapping[
                          tabValue - 1
                        ]?.mappingType
                      }
                      onChange={(e) => {
                        let result = { ...variableDialog };
                        result.database_object_model_fields_mapping[
                          tabValue - 1
                        ].mappingType = e.target.value;
                        setvariableDialog(result);
                      }}
                    >
                      <MenuItem value={"simple"}>Mono-sélection</MenuItem>
                      <MenuItem value={"address"}>Adresse</MenuItem>
                      <MenuItem value={"list_with_single_choice"}>
                        Mono-sélection dans une liste
                      </MenuItem>
                      <MenuItem value={"list_with_multiple_choices"}>
                        Multi-sélection dans une liste
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <RolesInput
                    variableDialog={variableDialog}
                    setvariableDialog={setvariableDialog}
                  ></RolesInput>
                  <Divider
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  />
                  {variableDialog.options[tabValue - 1].map(
                    (el, key) =>
                      el.id !== "__object_name__" && (
                        <div
                          key={key}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 5,
                          }}
                        >
                          <SubdirectoryArrowRightIcon />
                          <TextField
                            style={{
                              backgroundColor: "white",
                              marginLeft: 10,
                              marginRight: 10,
                              width: 400,
                            }}
                            label={"Element " + key}
                            size="small"
                            variant="outlined"
                            type="text"
                            value={
                              variableDialog.options[tabValue - 1][key].label
                            }
                            onChange={(e) => {
                              let result = { ...variableDialog };
                              result.options[tabValue - 1][key].label =
                                e.target.value;
                              setvariableDialog(result);
                            }}
                          />

                          {/* <SelectObjectModelField
                            variableDialog={variableDialog}
                            setvariableDialog={setvariableDialog}
                            elementKey={key}
                            tabValue={tabValue}
                          ></SelectObjectModelField> */}

                          <MappingComplexInput
                            style={{
                              backgroundColor: "white",
                              width: "100%",
                            }}
                            variableFieldName={
                              // "Nom"
                              variableDialog.options[tabValue - 1][key].label
                            }
                            variableFieldKey={42}
                            objectNumber={tabValue - 1}
                            variableDialog={variableDialog}
                            setvariableDialog={setvariableDialog}
                          ></MappingComplexInput>

                          <FormControl
                            size="small"
                            style={{
                              width: 200,
                              backgroundColor: "white",
                              marginLeft: 10,
                            }}
                            variant="outlined"
                          >
                            <InputLabel>Type</InputLabel>
                            <Select
                              label="Type"
                              value={
                                variableDialog.options[tabValue - 1][key].type
                              }
                              onChange={(e) => {
                                let result = { ...variableDialog };
                                result.options[tabValue - 1][key].type =
                                  e.target.value;
                                setvariableDialog(result);
                              }}
                            >
                              <MenuItem value={"text"}>Texte</MenuItem>
                              <MenuItem value={"number"}>Nombre</MenuItem>
                              <MenuItem value={"bool"}>Oui / Non</MenuItem>
                              <MenuItem value={"date"}>Date</MenuItem>
                            </Select>
                          </FormControl>
                          {el.type === "bool" ? (
                            <div
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginLeft: 10,
                                width: 300,
                              }}
                              onClick={() => {
                                let result = { ...variableDialog };
                                result.placeholder[tabValue - 1][key].value =
                                  Boolean(
                                    !result.placeholder[tabValue - 1][key].value
                                  );
                                setvariableDialog(result);
                              }}
                            >
                              Cochée par défaut
                              <Checkbox
                                checked={
                                  variableDialog.placeholder[tabValue - 1][key]
                                    .value
                                }
                              />
                            </div>
                          ) : (
                            <TextField
                              label={
                                el.type === "date"
                                  ? false
                                  : "Valeur pré-remplie"
                              }
                              value={
                                variableDialog.placeholder[tabValue - 1][key]
                                  .value
                              }
                              onChange={(e) => {
                                let result = { ...variableDialog };
                                result.placeholder[tabValue - 1][key].value =
                                  el.type === "number"
                                    ? +e.target.value
                                    : e.target.value;
                                setvariableDialog(result);
                              }}
                              type={el.type}
                              variant="outlined"
                              size="small"
                              style={{
                                marginLeft: 10,
                                backgroundColor: "white",
                                width: 300,
                              }}
                            />
                          )}
                          <Button
                            size="small"
                            variant="contained"
                            color="secondary"
                            disabled={key === 1}
                            style={{ marginLeft: 15 }}
                            onClick={() => {
                              let result = { ...variableDialog };
                              result.options[tabValue - 1] = result.options[
                                tabValue - 1
                              ].filter((option) => option.id !== el.id);
                              result.placeholder[tabValue - 1] =
                                result.placeholder[tabValue - 1].filter(
                                  (option) => option.id !== el.id
                                );
                              setvariableDialog(result);
                            }}
                          >
                            <RemoveIcon />
                          </Button>
                        </div>
                      )
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: "bold",
                      paddingBottom: 10,
                      paddingTop: 20,
                    }}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        let result = { ...variableDialog };
                        const id = makeid(8);
                        result.options[tabValue - 1].push({
                          id,
                          label: "",
                          type: "text",
                        });
                        result.placeholder[tabValue - 1].push({
                          id,
                          value: "",
                        });
                        setvariableDialog(result);
                      }}
                    >
                      <AddIcon style={{ fontSize: 18, marginRight: 10 }} />{" "}
                      Ajouter élément
                    </Button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      fontWeight: "bold",
                      paddingBottom: 10,
                      paddingTop: 20,
                    }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={variableDialog.options.length <= 1}
                      onClick={() => {
                        let result = { ...variableDialog };
                        result.options.splice(tabValue - 1, 1);
                        result.database_object_model_fields_mapping.splice(
                          tabValue - 1,
                          1
                        );
                        result.placeholder.splice(tabValue - 1, 1);
                        setTabValue("1");
                        setvariableDialog(result);
                      }}
                    >
                      Supprimer l'objet
                    </Button>
                  </div>
                </TabPanel>
              </TabContext>
            </Box>
          )}
        </Grid>
      </>
    )
  );
};
