export default function uploadNewDoc({
  setloading,
  updateDocument,
  copyDocument,
  setTabIndex,
  newFile,
}) {
  setloading(true)
  getDoc(newFile).then((el) => {
    if (copyDocument.pdf_mapping) {
      copyDocument.pdf = true
    }
    console.log("uploadMachineDoc", copyDocument)
    updateDocument({
      ...copyDocument,
      main: `a`,
      uploadedFile: el,
      title: newFile.name
        .split("")
        .reverse()
        .join("")
        .split(".")[1]
        .split("")
        .reverse()
        .join(""),
    })
    setloading(false)
    setTabIndex(1)
  })
}

// export default function uploadNewDocx({ setloading, updateDocument, copyDocument, setTabIndex, newFile }) {
//     setloading(true);
//     TemplateLibInterview.createDocumentDocx(
//         document.id,
//         input.files[0]
//       )
//         .then((result) => {
//           input.value = "";
//           toast.success("Le fichier a bien été mis à jour.");
//         })
//         .catch((error) => {
//           input.value = "";
//           toast.error(error.response.data.errors);
//         });
//         setloading(false);
//         setTabIndex(1);
// }

export async function getDoc(file) {
  // return await new Promise(resolve => {
  //     let ajax = new XMLHttpRequest();
  //     ajax.open("POST", "https://ej2services.syncfusion.com/production/web-services/api/documenteditor/Import", true);
  //     ajax.onreadystatechange = () => {
  //         if (ajax.readyState === 4) {
  //             if (ajax.status === 200 || ajax.status === 304) {
  //                 resolve(ajax.responseText);
  //             }
  //         }
  //     };
  //     let formData = new FormData();
  //     formData.append("files", file);
  //     ajax.send(formData);
  // });

  console.log("getDoc")
  return undefined
}
