import SaveButton from "./SaveButton"
import { Grid } from "@material-ui/core/"
import Insertion from "./Insertion"
import PdfMapping from "./PdfMapping"

export default function Summary() {
  return (
    <Grid container spacing={2}>
      <Grid
        item
        lg={6}
        md={12}
        sm={12}
        xs={12}
        style={{ padding: 40, maxHeight: "200px" }}
      >
        <h3>Gestion des documents</h3>
        <SaveButton />
      </Grid>
      <Grid
        item
        lg={6}
        md={12}
        sm={12}
        xs={12}
        style={{ padding: 40, maxHeight: "300px" }}
      >
        <h3>Variables</h3>
        <Insertion />
      </Grid>

      <Grid item lg={12} md={12} sm={12} xs={12} style={{ padding: 0 }}>
        <PdfMapping />
      </Grid>
    </Grid>
  )
}
