import { Grid, TextField, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RemoveIcon from "@material-ui/icons/Remove";
import { useEffect } from "react";

export default ({
  variableDialog,
  setvariableDialog,
  setoriginalvariableType,
  originalvariableType,
}) => {
  useEffect(() => {
    if (originalvariableType !== "choice") {
      setvariableDialog({
        ...variableDialog,
        options: ["", ""],
        placeholder: ["", ""],
      });
      setoriginalvariableType("choice");
    }
  }, []);

  return (
    originalvariableType === "choice" && (
      <>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{
            backgroundColor: "#eee",
            margin: "0 20px",
            border: "1px solid silver",
          }}
        >
          {variableDialog.options?.length &&
            variableDialog.options?.map((el, key) => (
              <div
                key={key}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 5,
                }}
              >
                <RadioButtonCheckedIcon />
                <TextField
                  style={{
                    backgroundColor: "white",
                    marginLeft: 20,
                    marginRight: 20,
                    width: 500,
                  }}
                  label={"Element " + (key + 1)}
                  size="small"
                  fullWidth
                  variant="outlined"
                  value={variableDialog.options[key]}
                  onChange={(e) => {
                    let result = { ...variableDialog };
                    result.options[key] = e.target.value;
                    result.placeholder[key] = e.target.value;
                    setvariableDialog(result);
                  }}
                />
                {key > 1 && (
                  <Button
                    size="small"
                    variant="contained"
                    style={{ backgroundColor: "#f93b3b", color: "white" }}
                    onClick={() => {
                      let result = { ...variableDialog };
                      result.options = result.options.filter(
                        (option, index) => index !== key
                      );
                      result.placeholder = result.placeholder.filter(
                        (option, index) => index !== key
                      );
                      setvariableDialog(result);
                    }}
                  >
                    <RemoveIcon />
                  </Button>
                )}
              </div>
            ))}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              paddingBottom: 10,
              paddingTop: 20,
            }}
          >
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => {
                let result = { ...variableDialog };
                result.options.push("");
                setvariableDialog(result);
              }}
            >
              <AddIcon style={{ fontSize: 18, marginRight: 10 }} /> Ajouter
              choix
            </Button>
          </div>
        </Grid>

        {/* <Grid style={{ backgroundColor: "#eee" }} item lg={7} md={7} sm={12} xs={12}></Grid> */}
      </>
    )
  );
};
