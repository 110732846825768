////// React Core :
import ActiveEditorContext from "../../../../providers/ActiveEditorContext"
import TreeContext from "../../../../providers/TreeContext"
import Delete from "./Buttons/Delete"
import Modify from "./Buttons/Modify"
import CopyProject from "./Fields/CopyProject"
import DescriptionField from "./Fields/Description.js"
import ProjectEditor from "../ProjectEditor.js"
import EntitiesField from "./Fields/Entities"
import NameField from "./Fields/Name.js"
import Price from "./Fields/Price"
import SpecialitiesField from "./Fields/Specialities"
import SubdomainForm from "./Fields/SubdomainForm"
import SubdomainsField from "./Fields/Subdomains"
import messageError from "./Functions/messageError"
import TemplateLib from "@common/libs/template-lib"
import { Grid, Button, Checkbox } from "@material-ui/core"
import { useState, useContext } from "react"
import { toast } from "react-toastify"
import FiltersContext from "~/providers/FiltersContext"
import Preload from "./Fields/Preload"
import ProjectTaskStatuses from "./Fields/ProjectTaskStatuses"

export default function FormProject() {
  const { activeEditor, updateActiveEditor } = useContext(ActiveEditorContext)
  const { tree, updateTree } = useContext(TreeContext)
  const { filtersData, updateFiltersData } = useContext(FiltersContext)
  const [_loading, set_loading] = useState(false)

  const [_openDialog, set_openDialog] = useState(false)
  const [_dataDialog, set_dataDialog] = useState({
    specialty_id: undefined,
    name: "",
  })

  const [_formData, set_formData] = useState(activeEditor.data)

  const editProject = () => {
    set_loading(true)
    TemplateLib.editProject(
      activeEditor.data.id,
      { ..._formData, entities: [] },
      filtersData.site.domain
    )
      .then((res) => {
        TemplateLib.setSubdomainsToProject(
          activeEditor.data.id,
          _formData.subdomains
        ).then((response) => {
          let valid = true

          if (filtersData.speciality !== 1) {
            if (
              _formData.projectspecialties.indexOf(filtersData.speciality) ===
              -1
            ) {
              updateFiltersData({ ...filtersData, speciality: 1 })
              valid = false
            }
          }
          if (filtersData.categories !== null) {
            if (
              _formData.subdomains.findIndex(
                (el) => filtersData.categories.id === el.id
              ) === -1
            ) {
              updateFiltersData({ ...filtersData, categories: 1 })
              valid = false
            }
          }
          if (filtersData.entities.length) {
            if (
              _formData.set_entities.findIndex(
                (el) => filtersData.entities[0].id === el.id
              ) === -1
            ) {
              updateFiltersData({ ...filtersData, entities: [] })
              valid = false
            }
          }
          let realResult = { ..._formData }
          realResult.subdomains = response

          updateFiltersData(() => ({
            ...filtersData,
          }))

          // let result = { ...tree };
          // const projectIndex = result.projects.findIndex(
          //   (el) => el.id === activeEditor.root[0].id
          // );
          // result.projects[projectIndex] = _formData;
          // updateTree(result);
          toast.success(
            'Modification(s) du projet : "' + res.name + '" enregistrée(s).'
          )
          set_loading(false)

          updateActiveEditor({
            ...activeEditor,
            data: realResult,
          })
        })
      })
      .catch((err) => {
        messageError(err, "durant la modifcation du projet")
        set_loading(false)
      })
  }

  const deleteProject = () => {
    if (
      window.confirm("Supprimer le projet : " + activeEditor.data.name + " ?")
    ) {
      set_loading(true)
      TemplateLib.deleteProject(activeEditor.data.id)
        .then((res) => {
          updateActiveEditor({ type: null, data: null })
          let result = {
            ...tree,
            projects: tree.projects.filter(
              (el) => el.id !== activeEditor.root[0].id
            ),
          }
          const newExpanded = result.expanded.map((elem) => {
            if (elem.split("-")[0] === activeEditor.root_key[0]) {
            } else if (elem.split("-")[0] > activeEditor.root_key[0]) {
              const value = elem.split("-")
              value[0] = +value[0] - 1
              return value.join("-")
            } else return elem
          })
          updateTree({ ...result, selected: "", expanded: newExpanded })
          toast.success(
            'Suppression du projet : "' +
              activeEditor.data.name +
              '" enregistrée.'
          )
          set_loading(false)
        })
        .catch((err) => {
          set_loading(false)

          toast.error(
            <div>
              <div>Erreur survenue durant la suppression du projet :</div>
              <div
                dangerouslySetInnerHTML={{
                  __html: JSON.stringify(err.response.data)
                    .replaceAll(/[}{]/gi, " ")
                    .replaceAll("[", " ")
                    .replaceAll("]", " ")
                    .replaceAll(",", "<br/>"),
                }}
              />
            </div>
          )
        })
    }
  }

  const goToProject = () => {
    const link =
      process.env.COMMON_LAW_APP_URL +
      "clients/" +
      process.env.COMMON_LAW_NOCODE_CLIENT +
      `/projects/${_formData.id}`
    window.open(link, "_blank").focus()
  }

  const resetOrders = () => {
    if (
      Array.isArray(activeEditor.data.sections) &&
      activeEditor.data.sections.length > 0
    ) {
      set_loading(true)
      TemplateLib.editCategories(activeEditor.data.sections)
        .then((res) => {
          set_loading(false)
          updateFiltersData(() => ({
            ...filtersData,
          }))
          toast.success("Sauvegarde des ordres ok")
        })
        .catch((response) => {
          set_loading(false)
          config.handleErrorBackend({ response, message: "" })
        })
    }
  }

  const isSaveSectionsDisabled = () => {
    return (
      !Array.isArray(activeEditor.data.sections) ||
      (Array.isArray(activeEditor.data.sections) &&
        activeEditor.data.sections.length < 1)
    )
  }

  return (
    <Grid container spacing={2}>
      <SubdomainForm
        filtersData={filtersData}
        _openDialog={_openDialog}
        set_openDialog={set_openDialog}
        data={_dataDialog}
        set_dataDialog={set_dataDialog}
        _formData={_formData}
        set_formData={set_formData}
      />
      <NameField
        _formData={_formData}
        set_formData={set_formData}
        label="Nom du projet"
      />
      <Price
        _formData={_formData}
        set_formData={set_formData}
        label="Prix du projet"
      />
      <SpecialitiesField _formData={_formData} set_formData={set_formData} />
      <SubdomainsField
        _formData={_formData}
        set_formData={set_formData}
        _openDialog={_openDialog}
        set_openDialog={set_openDialog}
        _dataDialog={_dataDialog}
        set_dataDialog={set_dataDialog}
      />
      <EntitiesField _formData={_formData} set_formData={set_formData} />
      {/* <DescriptionField
        _formData={_formData}
        set_formData={set_formData}
        label="Description du projet"
      /> */}
      <ProjectEditor
        _formData={_formData}
        set_formData={set_formData}
      ></ProjectEditor>
      {/* <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      > */}
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          marginBottom: "0px",
          paddingBottom: "0px",
        }}
      >
        <Checkbox
          checked={_formData.composable}
          onChange={(e) =>
            set_formData({ ..._formData, composable: e.target.checked })
          }
        />
        <span
          style={{ cursor: "pointer" }}
          onClick={() =>
            set_formData({ ..._formData, composable: !_formData.composable })
          }
        >
          Projet composable
        </span>
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          marginTop: "0px",
          paddingTop: "0px",
        }}
      >
        <Checkbox
          checked={_formData.composite}
          onChange={(e) =>
            set_formData({ ..._formData, composite: e.target.checked })
          }
        />
        <span
          style={{ cursor: "pointer" }}
          onClick={() =>
            set_formData({ ..._formData, composite: !_formData.composite })
          }
        >
          Projet composite
        </span>
      </Grid>
      <div
        style={{
          // marginTop: "30px",
          marginBottom: "30px",
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-evenly",
        }}
      >
        <Delete _function={deleteProject} loading={_loading} />
        <Button
          variant="contained"
          color="primary"
          onClick={resetOrders}
          disabled={isSaveSectionsDisabled()}
        >
          Enregistrer sections
        </Button>
        <Button variant="contained" color="primary" onClick={goToProject}>
          Tester le projet
        </Button>
        <CopyProject filtersData={filtersData} />
        <Modify _function={editProject} loading={_loading} />
      </div>
      {/* &nbsp;
      <ProjectTaskStatuses _formData={_formData} set_formData={set_formData} /> */}
      &nbsp;
      <Preload _formData={_formData} set_formData={set_formData} />
      {/* </Grid> */}
    </Grid>
  )
}
